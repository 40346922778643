import "./MockBee.css";
import React from "react";
import Mockman from "mockman-js";
export const MockAPI = () => {
	return (
		<div className="MockAPI">
			<Mockman colorScheme="light" />
		</div>
	);
};
