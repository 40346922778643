export const bxIcons = {
	home: <i className="bx bx-home"></i>,
	userCircle: <i className="bx bx-user-circle"></i>,
	userPlus: <i className="bx bx-user-plus"></i>,
	trash: <i className="bx bx-trash"></i>,
	trashAlt: <i className="bx bx-trash-alt"></i>,
	archive: <i className="bx bx-archive"></i>,
	archiveIn: <i className="bx bx-archive-in"></i>,
	archiveOut: <i className="bx bx-archive-out"></i>,
	label: <i className="bx bx-label"></i>,
	palette: <i className="bx bx-palette"></i>,
	pin: <i className="bx bx-pin"></i>,
	pinned: <i className="bx bxs-pin"></i>,
	login: <i className="bx bx-log-in"></i>,
	logout: <i className="bx bx-log-out"></i>,
	edit: <i className="bx bx-edit"></i>,
	cross: <i className="bx bx-x"></i>,
	searchAlt2: <i className="bx bx-search-alt-2"></i>,
	moon: <i className="bx bxs-moon"></i>,
	sun: <i className="bx bxs-sun"></i>,
	rightArrow: <i className="bx bx-right-arrow-alt"></i>,
	filterAlt: <i className="bx bx-filter-alt"></i>,
	playlist: <i className="bx bxs-playlist"></i>,
	like: <i className="bx bx-like"></i>,
	liked: <i className="bx bxs-heart"></i>,
	likedThumb: <i className="bx bxs-like"></i>,
	watchLater: <i className="bx bx-stopwatch"></i>,
	watchLaterSelected: <i className="bx bxs-stopwatch"></i>,
	history: <i className="bx bx-history"></i>,
};
